import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import getUserData from "../utils/userDataService";
import { setUser } from "../redux/features/userSlice";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IMAGES from "../img/image";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import "./ProductInfo.css";

const ProductInfo = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("WALLET");
  const [payment, setPayment] = useState("WALLET");
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [orderId, setOrderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [balance, setBalance] = useState("");
  const [loader, setLoader] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [popup, setPopup] = useState(false);
  const [userIdImage, setUserIdImage] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState(false);

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOrderId = () => {
    const numbers = "01234567"; // 10 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const orderId = randomNumbers.join("");
    setOrderId(orderId);
  };

  useEffect(() => {
    getProduct();
  }, [params?.name]);

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      setCheckLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        region: "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: "13",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setCheckLoading(false);
      } else {
        message.error(res.data.message);
        setCheckLoading(false);
      }
    } catch (error) {
      console.log(error);
      setCheckLoading(false);
    }
  }

  useEffect(() => {
    if (product?.apiName === "smileOne" && zoneId?.length >= 4) {
      handleCheckPlayer();
    }
  }, [zoneId?.length]);

  function handleConfirmOrder() {
    if (product?.api === "no") {
      if (userId === "") {
        return message.error("Some Fields are missing");
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
      if (product?.gameName === "Clash Of Clans") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
      if (product?.gameName === "Genshin Impact") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    } else if (product?.apiName === "moogold") {
      // mlbb & genshin
      if (product?.gameName === "428075" || product?.gameName === "15145") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
      if (product?.gameName === "13465") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
    } else {
      if (userId === "") {
        return message.error("Enter User ID");
      }
      if (zoneId === "") {
        return message.error("Enter Zone ID");
      }
      if (playerCheck === null) {
        return message.error("Check Your Player Name");
      }
    }
    setConfirmOrder(true);
  }

  function checkPlaceOrder(e) {
    if (product?.api === "yes") {
      if (product?.apiName === "smileOne") {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (product?.apiName === "moogold") {
        if (mode === "UPI") {
          handleMoogoldUpiOrder(e);
        } else {
          handleMoogoldWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://thecloverstores.com/api/payment/check-api-upi-order?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/payment/place-order-from-wallet",
        orderObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setLoader(false);
        setLoading(false);
        setOrderSuccess(true);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://thecloverstores.com/api/moogold/check-moogold-upi-order?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: product?.api,
        order_id: orderId,
        product_name: amount + "@" + product?.gameName,
        txn_amount: selectedPrice,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note: userId + "@" + zoneId + "@" + productId + "@" + product?.name,
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/moogold/place-moogold-from-wallet",
        orderObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        setLoader(false);
        setOrderSuccess(true);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }

  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://thecloverstores.com/api/payment/check-manual-upi-order?orderId=${orderId}`,
        txn_note: userId + "@" + amount,
      };
      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-manual-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/payment/place-order-non-api",
        orderObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        setLoader(false);
        setOrderSuccess(true);
      } else {
        message.error(res.data.message);
        setLoading(false);
        setLoader(false);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      setLoading(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  return (
    <Layout>
      {loader ? (
        <div className="loading-container">
          <div class="spinner center">
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
          </div>
        </div>
      ) : orderSuccess ? (
        <div className="order-success-container">
          <CheckCircleIcon className="icon" />
          <div className="order-container">
            <h4 className="text-center">Order Successful </h4>
            <div className="order-item">
              <span>Order Id</span>
              <span>{orderId}</span>
            </div>
            <div className="order-item">
              <span>Product</span>
              <span>{product?.name}</span>
            </div>
            <div className="order-item">
              <span>Item</span>
              <span>{amount}</span>
            </div>
            <div className="order-item">
              <span>User Id</span>
              <span>{userId}</span>
            </div>
            {zoneId !== "" && (
              <div className="order-item">
                <span>Zone Id</span>
                <span>{orderId}</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="productpagecontainer">
          <div
            className="p-info-container area"
            style={{
              backgroundImage: `url(https://thecloverstores.com/${product?.bannerImage?.replace(
                /\\/g,
                "/"
              )})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="pro-img">
              <img
                src={`https://thecloverstores.com/${product?.image}`}
                alt=""
              />
              <div>
                <h2 className="m-0">{product?.name}</h2>
                <h6 className="m-0 d-none d-md-none d-lg-block">
                  {product?.descTwo}
                </h6>
                <div className="features">
                  <button>
                    <SupportAgentIcon className="icon" />
                    24/7 Chat Support
                  </button>
                  <button>
                    <AssuredWorkloadIcon className="icon" />
                    Safe Payment
                  </button>
                  <button>
                    <VerifiedUserIcon className="icon" />
                    Official Store
                  </button>
                  <button>
                    <WorkspacePremiumIcon className="icon" />
                    Service Guarantee
                  </button>
                  <button>
                    <ElectricBoltIcon className="icon" />
                    Instant Delivery
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="instruction-image">
            <button onClick={() => setPopup(!popup)}>
              <InfoIcon className="icon me-1" />
              How to Purchase
            </button>
            <div className={`ins-popup ${popup && "active"}`}>
              <img
                src={`https://thecloverstores.com/${product?.instructionImage}`}
                alt=""
              />
              <CancelIcon
                className="icon close-icon"
                onClick={() => setPopup(!popup)}
              />
            </div>
          </div>

          <div className="order-info">
            {/* ORDER INFO  */}
            <div className="pack-info d-block d-md-block d-lg-none">
              <div>
                Order Information{" "}
                <HelpIcon
                  className="icon"
                  onClick={() => setUserIdImage(!userIdImage)}
                />
                <div className={`helpimg ${userIdImage && "active"}`}>
                  <CancelIcon onClick={() => setUserIdImage(!userIdImage)} />
                  <img src={product?.userIdImage} alt="" />
                </div>
              </div>
              {product?.apiName === "smileOne" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  {checkLoading && (
                    <>
                      <div
                        class="spinner-border spinner-border-sm me-2 mt-2"
                        role="status"
                      >
                        <span class="visually-hidden"></span>
                      </div>
                      Checking Username
                    </>
                  )}
                  <div>
                    <span className="text-success">
                      {playerCheck && "Username: " + playerCheck}
                    </span>
                  </div>
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "ML Region Luar" ||
                  product?.gameName === "Mobile Legends") ? (
                <>
                  {/* <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="USER ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                  <button className="p-check-btn" onClick={handleCheckPlayer}>
                    Check
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder="(    ZONE ID    )"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                <span className="text-danger">
                  {error &&
                    "First Check your username by clicking check button"}
                </span>
                <span className="text-success">
                  {playerCheck && "Username: " + playerCheck}
                </span> */}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Genshin Impact" ||
                  product?.gameName === "Honkai Star Rail") ? (
                <>
                  {/* <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="USER ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <select
                  name="zoneid"
                  className="form-select"
                  onChange={(e) => setZoneId(e.target.value)}
                >
                  <option value="">Select Server</option>
                  <option value="America">America</option>
                  <option value="Asia">Asia</option>
                  <option value="Europe">Europe</option>
                  <option value="TW_HK_MO">TW_HK_MO</option>
                </select>
                <span className="text-danger">
                  {error &&
                    "First Check your username by clicking check button"}
                </span> */}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Free Fire" ||
                  product?.gameName === "Call Of Duty Mobile" ||
                  product?.gameName === "PUBG Mobile" ||
                  product?.gameName === "Valorant" ||
                  product?.gameName === "Clash Of Clans" ||
                  product?.gameName === "League of Legends Wild Rift") ? (
                {
                  /* <input
                className="player-tag"
                type="text"
                name="userId"
                placeholder={`${product?.tag}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              /> */
                }
              ) : product?.apiName === "moogold" &&
                product?.gameName === "15145" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="p-check-btn" onClick={handleCheckPlayer}>
                    Check Username
                    {checkLoading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                  <span className="text-success">
                    {playerCheck && "Username: " + playerCheck}
                  </span>
                </>
              ) : product?.apiName === "moogold" &&
                (product?.gameName === "428075" ||
                  product?.gameName === "4233885") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select player-tag text-white"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW, HK, MO">TW, HK, MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : product?.apiName === "moogold" &&
                (product?.gameName === "5177311" ||
                  product?.gameName === "4427071") ? (
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder={product?.tag ? product?.tag : "Enter ID"}
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
              ) : (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              )}
            </div>
          </div>
          <div className="package-details">
            <div className="package-container">
              {product?.cost?.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      setAmount(item.amount);
                      setPriceAndId(item.amount);
                    }}
                    key={index}
                    className={`amount ${amount === item?.amount && "active"}`}
                  >
                    <div className="pack-details">
                      <div className="pack">
                        <p>{item?.amount}</p>
                        <p>{item?.pack}</p>
                      </div>
                      <div className="image">
                        <img src={item?.pimg} alt="pimg" />
                      </div>
                      <div className="price">
                        <p>
                          ₹
                          {user?.reseller === "yes"
                            ? item?.resPrice
                            : item?.price}
                        </p>
                        <p>₹{item?.fakePrice}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* ================================= FIELDS  */}
            {/* ================================= FIELDS  */}
            {/* ================================= FIELDS  */}
            <div className="order-info">
              <div className="pack-info d-none d-md-none d-lg-block">
                <div>
                  Order Information{" "}
                  <HelpIcon
                    className="icon"
                    onClick={() => setUserIdImage(!userIdImage)}
                  />
                  <div className={`helpimg ${userIdImage && "active"}`}>
                    <CancelIcon onClick={() => setUserIdImage(!userIdImage)} />
                    <img src={product?.userIdImage} alt="" />
                  </div>
                </div>
                {product?.apiName === "smileOne" ? (
                  <>
                    <div className="d-flex align-items-center">
                      <input
                        className="player-tag"
                        type="text"
                        name="userId"
                        placeholder="User ID"
                        onChange={(e) => setUserId(e.target.value)}
                        value={userId}
                      />
                    </div>
                    <input
                      className="player-tag"
                      type="text"
                      name="zoneid"
                      placeholder="(    ZONE ID    )"
                      onChange={(e) => setZoneId(e.target.value)}
                      value={zoneId}
                    />
                    {checkLoading && (
                      <>
                        <div
                          class="spinner-border spinner-border-sm me-2 mt-2"
                          role="status"
                        >
                          <span class="visually-hidden"></span>
                        </div>
                        Checking Username
                      </>
                    )}
                    <div>
                      <span className="text-success">
                        {playerCheck && "Username: " + playerCheck}
                      </span>
                    </div>
                  </>
                ) : product?.apiName === "yokcash" &&
                  (product?.gameName === "ML Region Luar" ||
                    product?.gameName === "Mobile Legends") ? (
                  <>
                    {/* <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="USER ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                  <button className="p-check-btn" onClick={handleCheckPlayer}>
                    Check
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder="(    ZONE ID    )"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                <span className="text-danger">
                  {error &&
                    "First Check your username by clicking check button"}
                </span>
                <span className="text-success">
                  {playerCheck && "Username: " + playerCheck}
                </span> */}
                  </>
                ) : product?.apiName === "yokcash" &&
                  (product?.gameName === "Genshin Impact" ||
                    product?.gameName === "Honkai Star Rail") ? (
                  <>
                    {/* <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="USER ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <select
                  name="zoneid"
                  className="form-select"
                  onChange={(e) => setZoneId(e.target.value)}
                >
                  <option value="">Select Server</option>
                  <option value="America">America</option>
                  <option value="Asia">Asia</option>
                  <option value="Europe">Europe</option>
                  <option value="TW_HK_MO">TW_HK_MO</option>
                </select>
                <span className="text-danger">
                  {error &&
                    "First Check your username by clicking check button"}
                </span> */}
                  </>
                ) : product?.apiName === "yokcash" &&
                  (product?.gameName === "Free Fire" ||
                    product?.gameName === "Call Of Duty Mobile" ||
                    product?.gameName === "PUBG Mobile" ||
                    product?.gameName === "Valorant" ||
                    product?.gameName === "Clash Of Clans" ||
                    product?.gameName === "League of Legends Wild Rift") ? (
                  {
                    /* <input
                className="player-tag"
                type="text"
                name="userId"
                placeholder={`${product?.tag}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              /> */
                  }
                ) : product?.apiName === "moogold" &&
                  product?.gameName === "15145" ? (
                  <>
                    <div className="d-flex align-items-center">
                      <input
                        className="player-tag"
                        type="text"
                        name="userId"
                        placeholder="User ID"
                        onChange={(e) => setUserId(e.target.value)}
                        value={userId}
                      />
                    </div>
                    <input
                      className="player-tag"
                      type="text"
                      name="zoneid"
                      placeholder="(    ZONE ID    )"
                      onChange={(e) => setZoneId(e.target.value)}
                      value={zoneId}
                    />
                    <button className="p-check-btn" onClick={handleCheckPlayer}>
                      Check Username
                      {checkLoading && (
                        <div class="spinner-grow spinner-grow-sm" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                    <span className="text-danger">
                      {error &&
                        "First Check your username by clicking check button"}
                    </span>
                    <span className="text-success">
                      {playerCheck && "Username: " + playerCheck}
                    </span>
                  </>
                ) : product?.apiName === "moogold" &&
                  (product?.gameName === "428075" ||
                    product?.gameName === "4233885") ? (
                  <>
                    <div className="d-flex align-items-center">
                      <input
                        className="player-tag"
                        type="text"
                        name="userId"
                        placeholder="USER ID"
                        onChange={(e) => setUserId(e.target.value)}
                        value={userId}
                      />
                    </div>
                    <select
                      name="zoneid"
                      className="form-select player-tag text-white"
                      onChange={(e) => setZoneId(e.target.value)}
                    >
                      <option value="">Select Server</option>
                      <option value="America">America</option>
                      <option value="Asia">Asia</option>
                      <option value="Europe">Europe</option>
                      <option value="TW, HK, MO">TW, HK, MO</option>
                    </select>
                    <span className="text-danger">
                      {error &&
                        "First Check your username by clicking check button"}
                    </span>
                  </>
                ) : product?.apiName === "moogold" &&
                  (product?.gameName === "5177311" ||
                    product?.gameName === "4427071") ? (
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder={product?.tag ? product?.tag : "Enter ID"}
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                ) : (
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder={`${product?.tag}`}
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                )}
              </div>

              <div className="mt-4 pack-info payment-container">
                <div className="title">
                  <p>Choose the payment method</p>
                </div>
                <div className="payment">
                  <div
                    onClick={() => {
                      setMode("WALLET");
                      setPayment("WALLET");
                    }}
                    className={`wallet ${payment === "WALLET" && "active"}`}
                  >
                    <div className="">
                      <div className="d-flex justify-content-center align-items-center">
                        <img className="coinimg" src={IMAGES.coin} alt="" />
                        <div className="ms-2">
                          <h6 className="m-0">Clover Coin</h6>
                          <span className="text-muted">
                            <small>Balance: {balance}</small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="text-end">
                      <span>
                        <img width="25px" src={IMAGES.coin} alt="" />{" "}
                        {selectedPrice}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setMode("UPI");
                      setPayment("UPI");
                    }}
                    className={`upi ${payment === "UPI" && "active"}`}
                  >
                    <div>
                      <img src={IMAGES.upi} alt="" />
                      <h6>UPI</h6>
                    </div>
                    <span>₹{selectedPrice}</span>
                  </div>
                  <div
                    onClick={() => {
                      setMode("UPI");
                      setPayment("PAYTM");
                    }}
                    className={`upi ${payment === "PAYTM" && "active"}`}
                  >
                    <div>
                      <img src={IMAGES.paytm} alt="" />
                      <h6>PAYTM</h6>
                    </div>
                    <span>₹{selectedPrice}</span>
                  </div>
                  <div
                    onClick={() => {
                      setMode("UPI");
                      setPayment("GPAY");
                    }}
                    className={`upi ${payment === "GPAY" && "active"}`}
                  >
                    <div>
                      <img src={IMAGES.gpay} alt="" />
                      <h6>GPAY</h6>
                    </div>
                    <span>₹{selectedPrice}</span>
                  </div>
                  <div
                    onClick={() => {
                      setMode("UPI");
                      setPayment("PHONEPE");
                    }}
                    className={`upi ${payment === "PHONEPE" && "active"}`}
                  >
                    <div>
                      <img src={IMAGES.phonepe} alt="" />
                      <h6>PHONEPE</h6>
                    </div>
                    <span>₹{selectedPrice}</span>
                  </div>
                </div>
              </div>

              <div className="mt-4 pack-info">
                <div className="title">
                  <span>Total</span>
                  <div className="price ">
                    {selectedPrice !== null ? (
                      <h3 className="m-0 mt-3">
                        <b>Rs. {selectedPrice}</b>
                      </h3>
                    ) : (
                      "Select an amount to see the price"
                    )}
                  </div>
                </div>
                {!user ? (
                  <button
                    className="p-check-btn"
                    onClick={() => navigate("/login")}
                  >
                    Please Login First
                  </button>
                ) : product?.stock === "No" ? (
                  <button className="p-check-btn" style={{ opacity: "0.7" }}>
                    Out of Stock
                  </button>
                ) : (
                  <button
                    disabled={loader}
                    onClick={handleConfirmOrder}
                    className="p-check-btn"
                  >
                    Buy Now
                    {loader && (
                      <div
                        class="ms-2 spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
              </div>
            </div>
            {/* ================================= FIELDS  */}
            {/* ================================= FIELDS  */}
            {/* ================================= FIELDS  */}

            <div className="pro-desc-container">
              <div className="pro-desc">
                <span>{product?.desc}</span>
              </div>
            </div>

            <div
              className={`${confirmOrder && "confirm-bs"}`}
              onClick={() => setConfirmOrder(false)}
            ></div>

            <div className={`confirm-popup ${confirmOrder && "active"} `}>
              <CancelIcon
                className="icon"
                onClick={() => setConfirmOrder(false)}
              />
              <div className="logo">
                <img src={IMAGES.hlogo} alt="" />
              </div>
              <div className="price">
                <span>Total Amount</span>
                <p>₹{selectedPrice}</p>
              </div>
              <table className="table table-bordered">
                <tr>
                  <td>User ID</td>
                  <td className="text-end">{userId}</td>
                </tr>
                <tr>
                  <td>Item</td>
                  <td className="text-end">{amount}</td>
                </tr>
                <tr>
                  <td>Payment Method</td>
                  <td className="text-end">{payment}</td>
                </tr>
              </table>
              <button
                className="buy-now confirmbtn w-100"
                onClick={checkPlaceOrder}
              >
                Confirm
              </button>
              <span className="mt-2">
                <small>
                  By clicking "Confirm" agree to{" "}
                  <Link to="/terms">User's Terms and Conditions</Link> &{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </small>
              </span>
              <div className="d-flex payment-secured">
                <VerifiedUserIcon className="icon" />
                <span>
                  Payment <br /> Secured
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ProductInfo;
