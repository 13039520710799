import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LoginIcon from "@mui/icons-material/Login";
import HistoryIcon from "@mui/icons-material/History";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import IMAGES from "../../img/image";
import "./SideMenu.css";
import { setUser } from "../../redux/features/userSlice";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [submenu, setSubmenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successful");
    dispatch(setUser(null));
    navigate("/login");
  };
  return (
    <div
      className={`sidemenu-container d-block d-md-block d-lg-none ${
        sideMenu ? "active" : ""
      }`}
    >
      <div className="sidemenu">
        <div className="logo">
          <img src={IMAGES.logo} alt="" />
          <HighlightOffIcon
            onClick={() => setSideMenu(!sideMenu)}
            className="icon"
          />
        </div>
        <ul className="p-0 ul">
          <>
            {user && (
              <li className="user">
                <AccountCircleIcon className="icon" />
                <span>{user?.email}</span>
              </li>
            )}
            <hr className="text-white" />
            <li>
              <Link onClick={() => setSideMenu(!sideMenu)} to="/games">
                <SportsEsportsIcon className="icon me-2" />
                Games
              </Link>
            </li>
            <li>
              <Link onClick={() => setSideMenu(!sideMenu)} to="/wallet">
                <AccountBalanceWalletIcon className="icon me-2" />
                Wallet
              </Link>
            </li>
            <li>
              <Link onClick={() => setSideMenu(!sideMenu)} to="/orders">
                <HistoryIcon className="icon me-2" />
                Order History
              </Link>
            </li>
            <li>
              <Link onClick={() => setSideMenu(!sideMenu)} to="/contact">
                <SupportAgentIcon className="icon me-2" />
                Contact Us
              </Link>
            </li>
          </>
          {user && (
            <li>
              <Link to="/user-dashboard">
                <AccountBoxIcon className="icon me-2" />
                My Account
              </Link>
            </li>
          )}
          <li>
            <Link to="https://wa.me/917005447895" target="_blank">
              <WhatsAppIcon className="icon me-2" />
              24/7 Chat Support
            </Link>
          </li>
          <hr className="text-white" />
          {!user && (
            <>
              <button
                className="signin"
                onClick={() => {
                  navigate("/login");
                  setSideMenu(!sideMenu);
                }}
              >
                Sign In
              </button>
              <button
                className="signin mt-2"
                onClick={() => {
                  navigate("/register");
                  setSideMenu(!sideMenu);
                }}
              >
                Sign Up Its Free
              </button>
            </>
          )}
          {user && (
            <li className="logout" onClick={handleLogout}>
              <LogoutIcon className="icon" />
              Logout
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
