import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import IMAGES from "../img/image";
import axios from "axios";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import getUserData from "../utils/userDataService";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./Wallet.css";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(1);
  const [form, setForm] = useState(null);
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [balance, setBalance] = useState("");
  const [price, setPrice] = useState("");
  const [payment, setPayment] = useState("UPI");

  const coinsArray = [
    {
      coins: 100,
      price: 100,
    },
    {
      coins: 500,
      price: 500,
    },
    {
      coins: 1000,
      price: 1000,
    },
    {
      coins: 2000,
      price: 2000,
    },
    {
      coins: 5000,
      price: 5000,
    },
    {
      coins: 10000,
      price: 10000,
    },
  ];

  useEffect(() => {
    getUserData(null, null, setBalance);
  }, []);

  const generateOrderId = () => {
    const timestamp = Date.now().toString(); // Convert current timestamp to string
    const randomNumbers = Array.from({ length: 3 }, () =>
      Math.floor(Math.random() * 10)
    );
    const orderId = timestamp + randomNumbers.join(""); // Append random numbers to timestamp
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
    setPrice(coinsArray[0].price);
  }, []);

  async function handlePay() {
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: price,
        txn_note: "Coin Topup",
        product_name: "Coin Topup",
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://thecloverstores.com/api/payment/check-wallet-payment-received?orderId=${orderId}`,
      };
      const response = await axios.post(
        "/api/payment/add-payment-to-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
    }
  }

  async function getUserPayments() {
    try {
      const res = await axios.post("/api/payment/get-user-payments", {
        email: user?.email,
      });
      if (res.data.success) {
        setPayments(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [e.target.name]: e.target.value });
    if (name === "amount") {
      if (value < 50) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      setForm((prev) => ({
        ...prev,
        email: user?.email,
        mobile: user?.mobile,
      }));
      getUserPayments();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="wallet-dash">
          {tab === 1 && (
            <div className="wallet-dash-card w-100 wallet-dash-card-one">
              <span>Total Balance</span>
              <p>₹{parseFloat(balance).toFixed(2)}</p>
              <span onClick={() => setTab(3)}>
                Add Coin
                <AddCircleIcon className="icon ms-1" />
              </span>
            </div>
          )}

          {/* USER HISTORY */}
          {tab === 0 && (
            <>
              <div className="backbtn text-white" onClick={() => setTab(1)}>
                <ArrowBackIosIcon className="icon" />
                <span>Back</span>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Amount</th>
                    <th>OrderId</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {payments &&
                    payments.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.amount}</td>
                          <td>{item?.orderId}</td>
                          <td>
                            {new Date(item?.createdAt).toLocaleString(
                              "default",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              }
                            )}
                          </td>
                          <td
                            className={
                              item?.status === "pending"
                                ? "text-warning"
                                : item?.status === "approved"
                                ? "text-success"
                                : item?.status === "rejected"
                                ? "text-danger"
                                : ""
                            }
                          >
                            {item?.status}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}

          {/* TXN HISTORY  */}
          {tab === 1 && (
            <div className="wallet-dash-card txn" onClick={() => setTab(0)}>
              <div>
                <FormatListBulletedIcon className="icon me-2" />
                Transaction History
              </div>
              <ArrowForwardIosIcon className="icon" />
            </div>
          )}

          {/* ADD COIN */}
          {tab === 3 && (
            <>
              <div className="add-coin-container">
                <div className="backbtn text-white" onClick={() => setTab(1)}>
                  <ArrowBackIosIcon className="icon" />
                  <span>Back</span>
                </div>
                <h4>Select your amount</h4>
                <div className="coin-container">
                  {coinsArray?.map((item, index) => {
                    return (
                      <div
                        className={`coin ${price === item?.price && "active"}`}
                        onClick={() => setPrice(item?.price)}
                      >
                        <p>{parseFloat(item?.coins).toFixed(2)} Coins</p>
                        <p>₹{parseFloat(item?.price).toFixed(2)}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-4 pack-info payment-container">
                  <div className="title">
                    <p>Choose the payment method</p>
                  </div>
                  <div className="payment">
                    <div
                      onClick={() => {
                        setPayment("UPI");
                      }}
                      className={`upi ${payment === "UPI" && "active"}`}
                    >
                      <div>
                        <img src={IMAGES.upi} alt="" />
                        <h6>UPI</h6>
                      </div>
                      <span>₹{price}</span>
                    </div>
                    <div
                      onClick={() => {
                        setPayment("PAYTM");
                      }}
                      className={`upi ${payment === "PAYTM" && "active"}`}
                    >
                      <div>
                        <img src={IMAGES.paytm} alt="" />
                        <h6>PAYTM</h6>
                      </div>
                      <span>₹{price}</span>
                    </div>
                    <div
                      onClick={() => {
                        setPayment("GPAY");
                      }}
                      className={`upi ${payment === "GPAY" && "active"}`}
                    >
                      <div>
                        <img src={IMAGES.gpay} alt="" />
                        <h6>GPAY</h6>
                      </div>
                      <span>₹{price}</span>
                    </div>
                    <div
                      onClick={() => {
                        setPayment("PHONEPE");
                      }}
                      className={`upi ${payment === "PHONEPE" && "active"}`}
                    >
                      <div>
                        <img src={IMAGES.phonepe} alt="" />
                        <h6>PHONEPE</h6>
                      </div>
                      <span>₹{price}</span>
                    </div>
                  </div>
                </div>
                <button
                  className="buy-now confirmbtn w-100"
                  onClick={handlePay}
                >
                  Confirm
                </button>
                <p className="mt-2 text-white text-center">
                  <small>
                    By cliking "Confirm" agree to{" "}
                    <Link to="/terms">User's Terms and Conditions</Link> &{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </small>
                </p>
                <div className="d-flex payment-secured">
                  <VerifiedUserIcon className="icon" />
                  <span>
                    Payment <br /> Secured
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;
